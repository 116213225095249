import { render, staticRenderFns } from "./XDataTable.vue?vue&type=template&id=04cad4ff&"
import script from "./XDataTable.vue?vue&type=script&lang=ts&"
export * from "./XDataTable.vue?vue&type=script&lang=ts&"
import style0 from "./XDataTable.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../public-charging/admin-portal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../public-charging/admin-portal/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCol,VDataTable,VDivider,VRow,VSimpleCheckbox,VSpacer,VTextField,VToolbar,VToolbarTitle})
